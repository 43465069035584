import { render, staticRenderFns } from "./SchedulePerson.vue?vue&type=template&id=b985f73a&scoped=true&"
import script from "./SchedulePerson.vue?vue&type=script&lang=js&"
export * from "./SchedulePerson.vue?vue&type=script&lang=js&"
import style0 from "./SchedulePerson.vue?vue&type=style&index=0&id=b985f73a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b985f73a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VCalendar,VDatePicker,VMenu,VTextField})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
